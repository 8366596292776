import React from 'react'
import TreeMenu from '../../Component/TreeMenu/TreeMenu';
import { useEffect, useState } from 'react';

export default function Main() {

    const [isLoggedin, setIsLoggedin] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem('_mail_token');
        if (token) {
            setIsLoggedin(true);
        } else {
            setIsLoggedin(false);
        }
    }, [])
    
    return (
        <div className='main-container-social '>
            <TreeMenu />
        </div>
    )
}
