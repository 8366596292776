// import "./global.css";
import "../../css/MenuDashboard.css";
import EmailIcon from "@mui/icons-material/Email";
import CircleIcon from "@mui/icons-material/Circle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import InsertChartOutlinedSharpIcon from "@mui/icons-material/InsertChartOutlinedSharp";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ScannerIcon from "@mui/icons-material/Scanner";
import Logo_removebg from '../../img/Logo_removebg.png'
import { useState } from "react";
// import DarkModeIcon from '@mui/icons-material/DarkMode';

export default function LeftDashboard({themeColor}) {

  const[DarkMode,setDarkMode]=useState(false);

  const handleThemeChange=()=>{
    setDarkMode(!DarkMode);
  }

  return (
    <div className={`${themeColor ? " left-section-wrapper":"themeColor-left-section-wrapper"}`}>

      <div className="left-menu">
        <ul className="menu-list white-txt">
          <li className="menu-item active-menu-item custom-pointer active-menu-item-list" title="Mail">
            <EmailIcon className="menu-icons" />
            {/* <p>Mail</p> 
             <CircleIcon className="active-circle" />*/}
          </li>
          <li className="menu-item active-menu-item custom-pointer" title="Meet">
            <VideocamOutlinedIcon className="menu-icons" />
            {/* <p>Meet</p> */}
          </li>
          <li className="menu-item active-menu-item custom-pointer" title="Calendar">
            <CalendarTodayOutlinedIcon className="menu-icons" />
            {/* <p>Calender</p> */}
          </li>
          <li className="menu-item active-menu-item custom-pointer" title="Chat">
            <ChatBubbleOutlineIcon className="menu-icons" />
            {/* <p>Chat</p> */}
          </li>
          
          <li className="menu-item active-menu-item custom-pointer" title="Sheet">
            <InsertChartOutlinedSharpIcon className="menu-icons" />
            {/* <p>Sheet</p> */}
          </li>
          <li className="menu-item active-menu-item custom-pointer" title="Docs">
            <FileCopyOutlinedIcon className="menu-icons"/>
            {/* <p>Docs</p> */}
          </li>
          <li className="menu-item active-menu-item custom-pointer" title="Drive">
            <ContentCopyOutlinedIcon className="menu-icons"/>
            {/* <p>Drive</p> */}
          </li>
          <li className="menu-item active-menu-item custom-pointer" title="Slide">
            <ScannerIcon className="menu-icons"/>
            {/* <p>Slide</p> */}
          </li>
        </ul>
      </div>
    </div>
  );
}
