export const pagePaths = {
    root: "/",
    write: "/write",
    bookmark:"/Bookmarks",
    createfilter:"/createfilter",
    filtermsg:"/filtermsg",
    forums:"/forums",
    important:"/important",
    forums:'/Forums',
    More:'/More',
    Nosplit:'/Nosplit',
    Prmotion:'/Promotion',
    RightOfInbox:'/RightOfInbox',
    Scheduled:'Scheduled',
    Sent:'Sent',
    Snoozed:'/Snoozed',
    spam:'/spam',
    Unread:'/Unread',
    Updates:'/Updates',
    Important:'/Important',
    Social:'/Social',
    // MainDashboard:'/',
    RightOfInbox :'/RightOfInbox',
    AllMails : '/AllMails',
    TextEditor:'.TextEditor',
    Login :'Login',
    
}