import "./css/global.css";
import MenuDashboard from "./Component/MenuDashboard/MenuDashboard";
import TreeMenu from "./Component/TreeMenu/TreeMenu";
import MainDashboard from "./Component/MainDashboard/MainDashboard";
import { Link } from "@mui/material";
import Header1 from "./Component/Header1/Header1";
import AppRouter from "./Router";
import { BrowserRouter as Router } from "react-router-dom";
function App() {
  return (
    <Router >
      <AppRouter />
    </Router>
  );
}
export default App;
