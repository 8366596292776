import "../../css/MainDashboard.css";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import Ellipse10 from "../../img/Ellipse10.jpg";
import Ellipse11 from "../../img/Ellipse11.jpg";
import Ellipse12 from "../../img/Ellipse12.jpg";
import Ellipse13 from "../../img/Ellipse13.jpg";
import Ellipse14 from "../../img/Ellipse14.jpg";
import { Checkbox } from "@mui/material";
import Footer from "../footer.js/Footer";
import { Link } from "react-router-dom";
import MailBox from "../MailBox/MailBox";
import { useEffect, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
// import Lockpassword from '../../icons/lockpassword.png';
// import showpasswoed from '../../icons/showpassword.png';
// import confirmpassword from '../../icons/confirmpassword.png';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Close, Padding } from "@mui/icons-material";
import LockResetIcon from '@mui/icons-material/LockReset';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import qs from "qs";


export default function MainDashboard({ Box, hide, themeColor }) {
  const [List, setList] = useState([]);
  const [SeenFlag, setSeenFlag] = useState(false);
  const [Mails, setMails] = useState(List);
  const [CountMails, setCountMails] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setlimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');

  const [password, setPassword] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [newShowPassword, setnewShowPassword] = useState(false);
  const [confirmNewshowPassword, setconfirmNewshowPassword] = useState(false);
  const [changepassword, setchangepassword] = useState(false);
  const [pageSplit, setpageSplit] = useState(true);
  const [bgcolor, setbgcolor] = useState("red");
  const [searchIconColor, setSearchIconColor] = useState(false)

  const [editMode, setEditMode] = useState(false);

  const email = localStorage.getItem("_mail_user");
  const username = email ? email.split("@")[0] : null;


  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const searchSVGICON = () => {
    setSearchIconColor(!searchIconColor)
  }
  const pageSplitClick = () => {
    setpageSplit(!pageSplit);
  }

  const setpageSplit1 = () => {
    setpageSplit(!pageSplit);

  }

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibilityNewPass = (e) => {
    e.preventDefault();
    setnewShowPassword(!newShowPassword);
  };

  const togglePasswordVisibilityConfirmPass = (e) => {
    e.preventDefault();
    setconfirmNewshowPassword(!confirmNewshowPassword);
  };
  const totalPages = Math.ceil(CountMails / limit);


  const handlePageChange = (page) => {
    setCurrentPage(page);
    setOffset((page - 1) * limit);
  };
  const changepasswordFun = () => {
    setchangepassword(!changepassword);
  }

  const changepasswordFun1 = () => {
    setchangepassword(false);
  }

  const handleChangePassword = async (password, newPassword, confirmPassword) => {
    const token = localStorage.getItem("_mail_token");
    const headers = {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json'
    };

    try {
      const url = "https://mail-api-prod.wezbomail.com/api/user/me";
      const response = await axios.get(url,
        {
          headers: headers
        });
      if (response.status === 200) {
        const data = response.data;
        const username = data.username;
        const domain = data.domain;
        const email = username + "@" + domain;

        let pass_change = qs.stringify({
          'new_password': newPassword,
          'password': password,
          'email': localStorage.getItem("_mail_user"),
          'new_cpassword': confirmPassword,
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://mail-api-prod.wezbomail.com/api/user/change_password',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: pass_change
        };
        axios.request(config)
          .then((response) => {
            if (response.status === 200 && response.data.success === true) {
              toast(response.data.message);
              localStorage.removeItem("_mail_token");
              localStorage.removeItem("_mail_user");
              window.location.href = "./";
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    }
    catch (error) {
      console.log(error);
    }
  }



  const handleFlagitem = (flagitem) => {
    if (flagitem == "UnSeen") {
      setSeenFlag(true);
    } else {
      setSeenFlag(false);
    }
  };

  const toCamelCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const fetchData = async () => {
    const token = localStorage.getItem("_mail_token");
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
    const queryParams = {
      limit: limit,
      offset: offset
    };
    const url = process.env.REACT_APP_MAIL_API_URL + "mail/" + Box + "/all";
    const queryString = Object.keys(queryParams)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key]))
      .join("&");
    try {
      const response = await axios.get(url + "?" + queryString, {
        headers: headers,
      });
      if (response.status === 200) {
        const data = response.data;
        setList(data.emails);
        setMails(data.emails);
        setCountMails(data.box.messages.total);
        if (SeenFlag === true) {
          const filteredList = data.emails.filter(
            (item) => item.attributes?.flags?.length === 0
          );
          setList(filteredList);
          setMails(filteredList);
          setCountMails(filteredList.length);
        }
      } else {
        const data = await response.json();
        setList(data.errors);
      }
    } catch (error) {
      setList(error)
    }
  };

  useEffect(() => {
    fetchData();
  }, [Box, limit, offset, SeenFlag]);

  useEffect(() => {
    async function fetchSearchResults() {
      const searchInput = search.toLowerCase();
      const filteredList = List?.filter(
        (item) =>
          item.subject?.includes(searchInput)
          || item.from.value.name?.includes(searchInput)
          || item.text?.includes(searchInput) || item.attachments[0]?.filename?.includes(searchInput)
      );
      setMails(filteredList);
      setList(filteredList);
      setCountMails(filteredList.length);
    }
    if (search.trim() !== '') {
      fetchSearchResults();
    }
  }, [search, List, limit]);

  return (
    <div className={hide ? "Top-main-widthincrease " : "Top-main"}>
      <div className="main-dash-wrapper">
        <div className="header-sec">
          <div className="heading-cont">
            <h1 className="reset-txt" style={{ fontWeight: "500" }}>{toCamelCase(Box)}</h1>
            {
              // (CountMails=== 0 ) ?(<p className="small-txt">0 Mails</p>):
              // <p className="small-txt">{CountMails} Mails</p>

              <p className="small-txt">{
                CountMails != 0 ? CountMails : 0
              } Mails
              </p>
            }
          </div>

          <div className={`${themeColor ? "search" : "searchdark"}`}>
            {!searchIconColor ? <SearchOutlinedIcon style={{ color: "white" }} /> :
              <SearchOutlinedIcon className="search-icon" style={{ color: "black" }} />}
            <input
              type="text"
              placeholder="Search"
              className="search-input"
              onChange={(e) => setSearch(e.target.value)}
              onFocus={searchSVGICON}
              onBlur={searchSVGICON}
            />
          </div>

          <div className="profile-detail">
            <p className="user-name white-txt"><b>{username}</b></p>
            <p title="Setting" className="setting"><SettingsOutlinedIcon className="setting-icon" /></p>
            <p title="Reset Password" className="resetpass"><LockResetIcon className="logout-icon" onClick={() => changepasswordFun()} /></p>
            <p title="Logout" className="logout"><LogoutIcon className="logout-icon" onClick={() => {
              localStorage.removeItem("_mail_token");
              window.location.href = "/login";
            }
            } />
            </p>
          </div>
          <ToastContainer />
          {changepassword &&
            (


              <div className="changepassword-main-container">
                <div className="changepassword-container">
                  <div className="close-icon-changepassword" ><CloseIcon style={{ color: 'black' }} onClick={() => changepasswordFun1()} /></div>
                  <h4>Change Password</h4>

                  <div className="changepassword-input">
                    <div className="lock-icon"><LockIcon style={{ color: "grey" }} /></div>
                    <button onClick={(e) => togglePasswordVisibility(e)} className='visibility-icon' style={{ color: "grey" }}>{showPassword ? <VisibilityIcon /> : < VisibilityOffIcon />}</button>
                    {/* <div className="input-change-password"> */}
                    <input type={showPassword ? 'text' : 'password'} value={password} name="password" placeholder="Password" required onChange={(e) => setPassword(e.target.value)} className="input-change-password" />
                    {/* </div> */}
                  </div>

                  <div className="changepassword-input">
                    <div className="lock-icon"><LockIcon style={{ color: "grey" }} /></div>
                    <button onClick={(e) => togglePasswordVisibilityNewPass(e)} className='visibility-icon' style={{ color: "grey" }}>{newShowPassword ? <VisibilityIcon /> : < VisibilityOffIcon />}</button>
                    <input type={newShowPassword ? 'text' : 'password'} value={newPassword} name="new-password" placeholder="New Password" required onChange={(e) => setnewPassword(e.target.value)} className="input-change-password" />
                  </div>


                  <div className="changepassword-input">
                    <div className="lock-icon"><LockIcon style={{ color: "grey" }} /></div>
                    <button onClick={(e) => togglePasswordVisibilityConfirmPass(e)} className='visibility-icon' style={{ color: "grey" }}>{confirmNewshowPassword ? <VisibilityIcon /> : < VisibilityOffIcon />}</button>
                    <input type={confirmNewshowPassword ? 'text' : 'password'} value={confirmPassword} name="confirm-new-password" placeholder="Confirm New Password" required onChange={(e) => setConfirmPassword(e.target.value)} className="input-change-password" />
                  </div>
                  <button className="changepassword-btn" onClick={() => handleChangePassword(password, newPassword, confirmPassword)}>Change Password</button>
                </div>
              </div>


              //   <div className="change-password-container">
              //   <h2 className="change-password-title">Change Password</h2>
              //   {
              //     <div className="change-password-form">
              //       <label htmlFor="newPassword" className="change-password-label">New Password:</label>
              //       <input type="password" id="newPassword" className="change-password-input" placeholder="Enter new password" />
              //       <label htmlFor="confirmNewPassword" className="change-password-label">Confirm New Password:</label>
              //       <input type="password" id="confirmNewPassword" className="change-password-input" placeholder="Confirm new password" />
              //       <button className="change-password-button" onClick={handleEditClick}>Change Password</button>
              //     </div>

              //   }
              // </div>
            )}
        </div>
        <div className="horizontal-menu">
          <ul className="horizontal-list reset-txt common-font">
            <li
              className={`list-item ${!SeenFlag ? 'active-list-item' : ''}`}
              onClick={() => handleFlagitem("All")}
            >
              All
            </li>
            <li
              // className={SeenFlag == true? "list-item active-list-item" : 'list-item'}
              className={`list-item ${SeenFlag ? 'active-list-item' : ''}`}
              onClick={() => handleFlagitem("UnSeen")}
            >
              Unread
            </li>
          </ul>
          <div className="pagination-container">
            <button className="lefticon-pagination" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || currentPage === 0}><ChevronLeftIcon /></button>
            <p className="currentpage"> {currentPage}</p><p className="currentpage">of</p><p className="currentpage">{totalPages}</p>
            <button className="righticon-pagination" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || totalPages === 0}><ChevronRightIcon /></button>

            {/* {!pageSplit ? <VerticalSplitIcon className="vertical-split" onClick={() => pageSplitClick()} /> : <DensitySmallIcon className="No-split" onClick={() => setpageSplit1()} />} */}
          </div>

        </div>
        <MailBox List={Mails} Box={Box} pageSplit={pageSplit} />
      </div>
    </div>
  );
}

// readmail-main-component-active