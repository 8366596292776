import { Routes, Route } from "react-router-dom"
import { pagePaths } from "../src/utils/constant"
// import Social from "./Component/Social/Social";

// import CreateFilter from "./Component/CreateFilter.js/CreateFilter";
// import Bookmarks from "./Pages/bookmarks/Bookmarks";
// import Filtermsg from "./Component/Filtermsg/Filtermsg";
// import Forums from './Component/Forums/Forums';
// import Nosplit from './Component/Nosplit/Nosplit';
// import RightOfInbox from "./Pages/inbox/RightOfInbox";
// import Scheduled from "./Pages/Scheduled/Scheduled";
// import Sent from './Pages/Sent/Sent';
// import Snoozed from './Pages/snoozed/Snoozed';
// import Spam from './Spam';
// import Updates from "./Component/Updates/Updates";
// import Important from "./Pages/Important/Important";
// import More from "./Component/More/More";
// import MainDashboard from "./Component/MainDashboard/MainDashboard";
// import Draft from "./Pages/Draft/Draft";
// import AllMails from "./Pages/Allmails/Allmails";
// import TextEditor from "./Component/TextEditor/TextEditor";
import Login from "./Pages/Login/Login";
import Main from "./Pages/Main/Main";
import Unread from "./Component/Unread/Unread";
import MainDashboard from "./Component/MainDashboard/MainDashboard";
import MailBox from "./Component/MailBox/MailBox";

const AppRouter = () => {
    return <Routes>
        <Route path={pagePaths.root} element={<Main />} />
        <Route path={pagePaths.Login} element={<Login />} />  
        <Route path={pagePaths.Unread} element={<Unread />} />
        <Route path="/" element={< MailBox/>} />
        {/* <Route path={pagePaths.Unread} element={<Unread />} /> */}
    </Routes>
}

export default AppRouter;