// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container{
  /* display: flex;
  flex-direction: column;
  align-items: flex-end; */
  /* width: 90%; */
}
.menuDataboard{
    /* padding: 30px 45px; */
    width: 17%;
    /* min-height: 958px; */
    background: linear-gradient(90deg, rgba(16,17,60,0.95) 0%, rgba(165,46,89,1) 100%);
}

.TreeMenu{
    width: 17%;
    padding: 40px;
}

.MainDashboard{
    width: 66%;
    /* padding: 25px 20px; */
    color: var(--white);
    /* min-height: 958px; */
    background: linear-gradient(90deg, rgba(16,17,60,0.95) 0%, rgba(165,46,89,1) 100%);
}

.footer-line {
    text-align: right;
    padding: 30px;
    /* border-top: 1px solid grey; */
}`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA;EACE;;0BAEwB;EACxB,gBAAgB;AAClB;AACA;IACI,wBAAwB;IACxB,UAAU;IACV,uBAAuB;IACvB,kFAAkF;AACtF;;AAEA;IACI,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,wBAAwB;IACxB,mBAAmB;IACnB,uBAAuB;IACvB,kFAAkF;AACtF;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,gCAAgC;AACpC","sourcesContent":[".footer-container{\r\n  /* display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-end; */\r\n  /* width: 90%; */\r\n}\r\n.menuDataboard{\r\n    /* padding: 30px 45px; */\r\n    width: 17%;\r\n    /* min-height: 958px; */\r\n    background: linear-gradient(90deg, rgba(16,17,60,0.95) 0%, rgba(165,46,89,1) 100%);\r\n}\r\n\r\n.TreeMenu{\r\n    width: 17%;\r\n    padding: 40px;\r\n}\r\n\r\n.MainDashboard{\r\n    width: 66%;\r\n    /* padding: 25px 20px; */\r\n    color: var(--white);\r\n    /* min-height: 958px; */\r\n    background: linear-gradient(90deg, rgba(16,17,60,0.95) 0%, rgba(165,46,89,1) 100%);\r\n}\r\n\r\n.footer-line {\r\n    text-align: right;\r\n    padding: 30px;\r\n    /* border-top: 1px solid grey; */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
