import React, { useState, useEffect, useRef } from 'react';
import './Unread.css';
import { saveAs } from 'file-saver';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddTaskIcon from '@mui/icons-material/AddTask';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import LabelIcon from '@mui/icons-material/Label';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReplyIcon from '@mui/icons-material/Reply';
import { ReactMultiEmail } from "react-multi-email";
import MinimizeIcon from '@mui/icons-material/Minimize';
import ReplyAllTwoToneIcon from '@mui/icons-material/ReplyAllTwoTone';
import TextEditor from '../TextEditor/TextEditor';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import SendIcon from '@mui/icons-material/Send';
import DraftsIcon from '@mui/icons-material/Drafts';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import axios from 'axios';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Unread = ({ Box, uid, splitMail, OpenedMail, pageSplit, themeColor }) => {

  let count = 0;
  let Allcount = 100;
  const [opendropdown, setOpendropdown] = useState(false);
  const [openmoreicon, setOpenmoreicon] = useState(false);
  const [OpenMailData, setOpenMailData] = useState("");
  const [openMail, setOpenedMail] = useState(false);
  const [Decrement, setLefDecrement] = useState(0);
  const [Increament, setRightIncreament] = useState(0);
  const [openMailNoSplit, setOpenMailNoSplit] = useState(false);
  const [openreply, setOpenreply] = useState(false);
  const [openforward, setOpenforward] = useState(false);
  const [openDilog, SetOpenDilog] = useState(false);

  const [emails, setEmails] = useState([]);
  const [ccemails, setCcemails] = useState([]);
  const [bccemails, setBccemails] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isComposeList, setIsComposeList] = useState([]);
  const [editorHtml, setEditorHtml] = React.useState('');
  const [placeholder, setPlaceholder] = useState('Recipients');
  const [activeCC, setActiveCC] = useState(false);
  const [activeBCC, setActiveBCC] = useState(false);
  const [hideInput, sethideInput] = useState(false)
  const [openreplyall, setOpenreplyall] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(true);
  const [bloburl, setBloburl] = useState("")


  const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const downloadFile = (base64String, fileName, contentType) => {
    const blob = base64ToBlob(base64String, contentType);
    saveAs(blob, fileName);
  };

  const handleDownload = (filename, contenttype, base64string) => {
    const base64String = base64string;
    const fileName = filename;
    const contentType = contenttype;

    const blob = new Blob([], { type: contentType });
    const blobUrl = URL.createObjectURL(blob);

    downloadFile(base64String, fileName, contentType);
  };

  const generateBlob = (filename, contenttype, base64string) => {
    const binaryData = atob(base64string);

    // Create a Uint8Array from binary data
    const arrayBuffer = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      arrayBuffer[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the array buffer
    const blob = new Blob([arrayBuffer], { type: contenttype });

    // Generate a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);
    console.log("blobUrl", blobUrl);
    setBloburl(blobUrl);
  }

  const handledisplayMessage = (value) => {
    setDisplayMessage(value)
  }

  const handleTextClick = () => {
    setActiveBCC(true);
  };
  const handleTextClickCC = () => {
    setActiveCC(true);
  };

  const handleFocus = () => {
    setPlaceholder('To');
  };
  const handleBlur = () => {
    setPlaceholder('Recipients');
  };
  const handleFocusCcBcc = () => {
    sethideInput(true);
  }
  const handleBlurCcBcc = () => {

  }

  const handleProcedureContentChange = (html) => {
    setEditorHtml(html);
  };

  const toCamelCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleMenuItemClick = async (uid, type) => {

    const token = localStorage.getItem("_mail_token");
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    var url = "";
    var message = "";
    if (type === "delete") {
      url = `${process.env.REACT_APP_MAIL_API_URL}mail/${Box}/move/Trash?uids=${uid}`;
      message = "Deleted";
    }

    else if (type === "archive") {
      var flag = "";
      if (type === "archive") {
        flag = toCamelCase(type);
      }
      url = `${process.env.REACT_APP_MAIL_API_URL}mail/${Box}/move/${flag}?uids=${uid}`;
      message = "Archived";
    }

    else if ((type === "star") || (type === "read")) {
      var flag = "";
      if (type === "star") {
        flag = "flagged";
      } else if (type === "read") {
        flag = "seen";
      }
      url = `${process.env.REACT_APP_MAIL_API_URL}mail/${Box}/flag/${uid}?flag=${flag}`;
      message = flag === "flagged" ? "Starred" : "Marked as read";
    }

    else if ((type === "unstar") || (type === "unread")) {
      var flag = "";
      if (type === "unstar") {
        flag = "flagged";
      } else if (type === "unread") {
        flag = "seen";
      }
      url = `${process.env.REACT_APP_MAIL_API_URL}mail/${Box}/flag/remove/${uid}?flag=${flag}`;
      message = flag === "flagged" ? "Unstarred" : "Marked as unread";
    }
    try {
      const response = await axios.post(url, {}, { headers });
      if (response.status === 200) {
        toast.success("Mail, " + message);
        if (message === "Deleted") {
          setTimeout(() => {
            window.location.href = '/'
          }, 1000);
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Session Expired");
        localStorage.clear();
        window.location.href = './login';
      }
    }
  }

  const ref = useRef(null);

  // const handleClick = () => {
  //   ref.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // use history for back button
  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const writeContainerRef = useRef(null);
  useEffect(() => {
    // Function to scroll to the bottom of the write-main-container
    const scrollToBottom = () => {
      if (writeContainerRef.current) {
        writeContainerRef.current.scrollTop = writeContainerRef.current.scrollHeight;
      }
    };

    if (openreply || openforward || openreplyall) {
      scrollToBottom();
    }
  }, [openreply, openforward, openreplyall]);

  const replyHandler = (to) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    setDisplayMessage(false)

    var emailsto = []
    for (let i = 0; i < to.length; i++) {
      emailsto.push(to[i].address)
    }
    setEmails(emailsto)
    setSubject("Re: " + OpenMailData?.headers?.subject)
    setOpenreply(!openreply)
    setOpenforward(false)
    setOpenreplyall(false)
  }

  const forwardHandler = (to, cc, bcc) => {
    setDisplayMessage(false)
    setOpenforward(!openforward);
    var emailsto = []
    for (let i = 0; i < to?.length; i++) {
      emailsto.push(to[i].address)
    }
    setEmails(emailsto)


    cc?.length > 0 && setActiveCC(true)
    var emailscc = []
    for (let i = 0; i < cc?.length; i++) {
      emailscc.push(cc[i].address)
    }
    setCcemails(emailscc)

    bcc?.length > 0 && setActiveBCC(true)
    var emailsbcc = []
    for (let i = 0; i < bcc?.length; i++) {
      emailsbcc.push(bcc[i].address)
    }
    setBccemails(emailsbcc)

    setOpenreply(false)
    setOpenreplyall(false)
  }

  const replyallHandler = (to, cc, bcc, div) => {
    setDisplayMessage(false)

    var emailsto = []
    for (let i = 0; i < to?.length; i++) {
      emailsto.push(to[i].address)
    }
    setEmails(emailsto)

    cc?.length > 0 && setActiveCC(true)
    var emailscc = []
    for (let i = 0; i < cc?.length; i++) {
      emailscc.push(cc[i].address)
    }
    setCcemails(emailscc)

    bcc?.length > 0 && setActiveBCC(true)
    var emailsbcc = []
    for (let i = 0; i < bcc?.length; i++) {
      emailsbcc.push(bcc[i].address)
    }
    setBccemails(emailsbcc)
    setOpenreplyall(true);
    setOpenforward(false);
    setOpenreply(false);
    scrollBottom();
  }

  const handlerContainer = () => {
    console.log("handlerContainer");
  };

  const scrollBottom = () => {
    window.scrollTo({
      top: 0
    })
  }

  const handleOpenMail = (e) => {
    e.preventDefault();
    window.location.href = '/';
  }

  const fetchData = async () => {
    const token = localStorage.getItem("_mail_token");
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
    const url = `${process.env.REACT_APP_MAIL_API_URL}mail/${Box}/uid/${uid}`;
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        const data = await response.data;
        setOpenMailData(data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Session Expired");
        localStorage.clear();
        window.location.href = './login';
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [Box, uid]);

  const lefDecrement = () => {
    if (count > 0) {
      setLefDecrement(count - 10);
    }
  };

  const rightIncreament = () => {
    if (count < Allcount) {
      setRightIncreament(count + 10);
    }
  };

  const openMoreIcon = () => {
    setOpenmoreicon(!openmoreicon);
  };

  const openDropdown = () => {
    setOpendropdown(!opendropdown);
  };


  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline",],
      [{ list: "ordered" },
      { list: "bullet" }
      ],

      ["link", "image"],
      [
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

  const SendMail = async () => {
    const to = emails.join(",");
    const ccEmail = ccemails.join(",");
    const bccEmail = bccemails.join(",");
    const subjectEmail = subject;
    const bodyEmail = editorHtml;
    const token = localStorage.getItem("_mail_token");
    const headers = {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json'
    };
    const data = {
      to: to,
      cc: ccEmail,
      bcc: bccEmail,
      subject: subjectEmail,
      html: bodyEmail,
      text: bodyEmail
    }

    const config = {
      url: process.env.REACT_APP_MAIL_API_URL + 'mail/sendmail',
      method: 'post',
      maxBodyLength: Infinity,
      headers: headers,
      data: data
    }
    try {
      const response = await axios(config);
      if (response.status === 200) {
      }
    }
    catch (error) {
      console.log("Error", error);
    }
  }

  const containerRef = useRef(null);

  // useEffect(() => {
  //   // Function to scroll to the bottom on component mount
  //   const scrollToBottom = () => {
  //     if (containerRef.current) {
  //       containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //     }
  //   };

  //   scrollToBottom();
  // }, []);


  return (
    <>
      {
        OpenedMail && (
          <div className={` ${!OpenedMail && pageSplit ? "readmail-main-component-active" : "readmail-main-component"}`}>
            <div className={`${themeColor ? "top-container-readmail" : "top-container-readmail-theme"}`}>
              <div className="readmail-icons">
                <p title='Back to Inbox' onClick={(e) => handleOpenMail(e)}><ArrowBackIcon className="arrow-back-icon" /></p>
                {/* <p title='Archive'><ArchiveIcon className="archive-icon" onClick={() => handleMenuItemClick(OpenMailData?.attributes.uid, "archive")} /></p> */}
                <p title="Delete"><DeleteIcon className="delete-icon" onClick={() => handleMenuItemClick(OpenMailData?.attributes.uid, "delete")} /></p>
                <p title='Mark as Unread'><MarkunreadIcon className="markunread-icon" onClick={() => handleMenuItemClick(OpenMailData?.attributes.uid, OpenMailData?.attributes?.flags.includes("\\Seen") ? "unread" : "read")} /></p>
                <p>|</p>
                {/* <AccessTimeIcon className="access-time-icon" /> */}
                {/* <AddTaskIcon className="add-task-icon" /> */}
                {/* <p>|</p>*/}
                {/* <DriveFileMoveIcon className="drive-file-move-icon" /> */}
                {/*<p title='Label'><LabelIcon className="label-icon" /></p>
                <p title='More'><MoreVertIcon className="more-vert-icon" /></p>*/}
                <p title='Reply'><ReplyIcon className="reply-all-icon" onClick={() => replyHandler(OpenMailData?.headers?.from?.value)} /></p>
                <p title='Forward'><ReplyIcon className="farword-icon" onClick={() => forwardHandler(OpenMailData?.headers?.to?.value, OpenMailData?.headers?.cc?.value, OpenMailData?.headers?.bcc?.value)} /></p>
                <p title='Reply All'><ReplyAllTwoToneIcon className="reply-all-icon" onClick={() => replyallHandler(OpenMailData?.headers?.to?.value, OpenMailData?.headers?.cc?.value, OpenMailData?.headers?.bcc?.value, 'div')} /></p>
              </div>
            </div>

            <div

              className="readmail-main-subject">
              <div className="subject-readmail">{OpenMailData?.headers?.subject}</div>
              <div className="from-readmail">
                <div className="frm-fst-read">
                  <p className="fromname-readmail">
                    <span className="fromeamil-readmail">{OpenMailData?.headers?.from?.text}</span>
                  </p>
                  <span className="tome-arrrowdown">To me
                    <p>{OpenMailData?.headers?.to?.value?.address}</p> <ArrowDropDownIcon onClick={() => openDropdown()} />
                    {opendropdown && (
                      <div className="arrowdropdown-main-container open">
                        <div className="arrowdropdown-submain-container rightsection">
                          <p className='arrowdropdown-label'>from:</p>
                          <p className="left-conent-arrowdropdown leftsection">{OpenMailData?.headers?.from?.text}</p>
                        </div>
                        <div className="arrowdropdown-submain-container rightsection">
                          <p className='arrowdropdown-label'>reply-to:</p>
                          <p className="left-conent-arrowdropdown">{ }</p>
                        </div>
                        <div className="arrowdropdown-submain-container rightsection">
                          <p className='arrowdropdown-label'>to:</p>
                          <p className="left-conent-arrowdropdown">{OpenMailData?.headers?.to?.text}</p>
                        </div>
                        <div className="arrowdropdown-submain-container rightsection">
                          <p className='arrowdropdown-label'>date:</p>
                          <p className="left-conent-arrowdropdown">{OpenMailData?.headers?.date}</p>
                        </div>
                        <div className="arrowdropdown-submain-container rightsection">
                          <p className='arrowdropdown-label'>subject:</p>
                          <p className="left-conent-arrowdropdown">{OpenMailData?.headers?.subject}</p>
                        </div>
                        <div className="arrowdropdown-submain-container rightsection">
                          <p className='arrowdropdown-label'>mailed-by:</p>
                          <p className="left-conent-arrowdropdown">{OpenMailData?.headers?.from?.text}</p>
                        </div>
                        <div className="arrowdropdown-submain-container rightsection">
                          <p className='arrowdropdown-label'>signed by:</p>
                          <p className="left-conent-arrowdropdown">{ }</p>
                        </div>
                        <div className="arrowdropdown-submain-container rightsection">
                          <p className='arrowdropdown-label'>security:</p>
                          <p className="left-conent-arrowdropdown">{ }</p>
                        </div>
                      </div>
                    )}
                  </span>
                </div>

                <div className='frm-read'>
                  <div className="from-readmail-right-contaier">
                    <p>{OpenMailData?.headers?.date}</p>
                    <StarBorderIcon className={`${OpenMailData?.attributes?.flags.includes("\\Flagged") ? "star-fill" : "star-border-icon"}`} onClick={() => handleMenuItemClick(OpenMailData?.attributes.uid, OpenMailData?.attributes?.flags.includes("\\Flagged") ? "unstar" : "star")} />
                    {/* <ReplyIcon className="reply-icon" />
                    <MoreVertIcon className="more-vert-icon" onClick={() => openMoreIcon()} /> */}
                  </div>
                </div>
              </div>̦

              {OpenMailData?.bodyText && OpenMailData.bodyText.trim().startsWith('<p>') ? (
                <p className="body_plaintext" dangerouslySetInnerHTML={{ __html: OpenMailData.bodyText }}></p>
              ) : (

                (<div style={{ color: "gray" }} className="readmail-content-container">
                  <div className="readmail-inner-content-container">
                    <div className={`${!displayMessage ? "readmail-content-mail-reduceheight" : "readmail-content-mail"} `} onClick={() => handledisplayMessage(!displayMessage)}
                      dangerouslySetInnerHTML={{
                        __html:
                          DOMPurify.sanitize(OpenMailData?.bodyHtml !== "undefined"
                            ? OpenMailData.bodyHtml
                            : OpenMailData?.bodyTextAsHtml)
                      }
                      }
                    >
                    </div>

                  </div>

                </div>)
              )}
              {OpenMailData?.attachments?.length > 0 && (
                <div>
                  <a href={bloburl} target="_blank" onClick={() => generateBlob(OpenMailData.attachments[0]?.filename, OpenMailData.attachments[0]?.contentType, OpenMailData.attachments[0]?.content)} title="File Preview" width="100%" height="400" >{OpenMailData.attachments[0]?.filename}</a>
                </div>
              )
              }
              {
                (openreply || openforward || openreplyall) && (
                  <div className="main-write-container" ref={writeContainerRef} >
                    <div className="submain-write-container-fullwidth">

                      {
                        // inputFields.map((input, index) => (
                        <div className="write-input1 TO-input" style={{ marginBottom: '10px' }}>
                          <ReactMultiEmail
                            className="reactMultiEmail-emails"
                            placeholder={placeholder}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            emails={emails}
                            onChange={(_emails) => {
                              setEmails(_emails);
                            }}
                            validateEmail={(email) => {
                              return true;
                            }}
                            getLabel={(email, index, removeEmail) => {
                              return (
                                <div data-tag key={index}>
                                  {email}
                                  <span data-tag-handle onClick={() => removeEmail(index)}>
                                    X
                                  </span>
                                </div>
                              );
                            }}
                          />

                          {
                            !openreply &&
                            (!openforward || !openreplyall) &&
                            (
                              <div className={`cc-bcc-container ${activeBCC || activeCC ? "hide-bcc-cc" : "hide"}`}>
                                <span className="CC-text" onClick={handleTextClickCC}>CC</span>
                                <span className="BCC-text" onClick={handleTextClick}>BCC</span>
                              </div>
                            )
                          }

                        </div>
                        // ))
                      }


                      {
                        !openreply &&
                        (!openforward || !openreplyall) &&
                        activeCC &&
                        (<div className="write-input1 write-CC" >
                          <ReactMultiEmail
                            className="reactMultiEmail-emails"
                            placeholder="CC"
                            onFocus={handleBlurCcBcc}
                            onBlur={handleBlurCcBcc}
                            emails={ccemails}
                            onChange={(_emails) => {
                              setCcemails(_emails);
                            }}
                            validateEmail={(ccemails) => {
                              return true;
                            }}
                            getLabel={(ccemails, index, removeEmail) => {
                              return (
                                <div data-tag key={index}>
                                  {ccemails}
                                  <span data-tag-handle onClick={() => removeEmail(index)}>
                                    X
                                  </span>
                                </div>
                              );
                            }}
                          />
                          <div className={`cc-bcc-container ${activeBCC && activeCC ? "hide-bcc-cc" : "hide"}`}><span className="BCC-text" onClick={handleTextClick}>BCC</span></div>
                        </div>)


                      }

                      {
                        !openreply &&
                        (!openforward || !openreplyall) &&
                        activeBCC &&
                        (<div className="write-input1 write-BCC" >
                          <ReactMultiEmail
                            className="reactMultiEmail-emails"
                            placeholder="BCC"
                            onFocus={handleFocusCcBcc}
                            onBlur={handleBlurCcBcc}
                            emails={bccemails}
                            onChange={(_emails) => {
                              setBccemails(_emails);
                            }}
                            validateEmail={(bccemails) => {
                              return true;
                            }}
                            getLabel={(bccemails, index, removeEmail) => {
                              return (
                                <div data-tag key={index}>
                                  {bccemails}
                                  <span data-tag-handle onClick={() => removeEmail(index)}>
                                    X
                                  </span>
                                </div>
                              );
                            }}
                          />
                          <div className={`cc-bcc-container ${activeBCC && activeCC ? "hide-bcc-cc" : "hide"}`}><span className={`CC-text ${activeBCC || activeCC} ? "hide-bcc-cc" : "hide"`} onClick={handleTextClickCC}>CC</span></div>
                        </div>)

                      }


                      {/* <div className="CCBC-txt"><p>CC</p><p>BC</p></div> */}
                      <input className="CCBC-hide write-input" type="email" placeholder="CC" />
                      <input className="CCBC-hide write-input" type="email" placeholder="BCC" />
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder="write message here ...."
                        onChange={handleProcedureContentChange}
                        style={{ width: '100%' }}
                      >
                      </ReactQuill>
                      {/* <TextEditor /> */}
                      <div className="write-input">
                        {OpenMailData?.bodyText && OpenMailData.bodyText.trim().startsWith('<p>') ? (
                          <p className="body_plaintext" dangerouslySetInnerHTML={{ __html: OpenMailData.bodyText }}></p>
                        ) : (
                          (<div style={{ color: "gray" }} className="readmail-content-container">
                            <div className="readmail-inner-content-container">
                              <div className="readmail-content-mail"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    DOMPurify.sanitize(OpenMailData?.bodyHtml !== "undefined"
                                      ? OpenMailData.bodyHtml
                                      : OpenMailData?.bodyTextAsHtml)
                                }
                                }
                              >
                              </div>

                            </div>

                          </div>)
                        )}
                      </div>
                      <div className='reply-btns'><button className="write-btn-forward" onClick={() => SendMail([emails, ccemails, bccemails, subject, editorHtml])}>{openreply && "Reply"} {openforward && "Forward"} {openreplyall && "Reply All"}</button></div>
                    </div>
                  </div>
                )

              }
            </div>
          </div >
        )

      }
    </>
  );
}

export default Unread;