import "../../css/TreeMenu.css";
import React from "react";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Logo_removebg from '../../img/Logo_removebg.png'
import MenuDashboard from '../../Component/MenuDashboard/MenuDashboard';
import MainDashboard from '../../Component/MainDashboard/MainDashboard';
import { useState, useEffect } from "react";
import { pagePaths } from "../../utils/constant";
import axios from "axios";
import { ReactMultiEmail } from "react-multi-email";
// import TextEditor from "../TextEditor/TextEditor";
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CreateIcon from '@mui/icons-material/Create';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SendIcon from '@mui/icons-material/Send';
import DraftsIcon from '@mui/icons-material/Drafts';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import InboxIcon from '@mui/icons-material/Inbox';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import { useHistory } from "react-router-dom";
// import './TreeMenu.css'
import '../../css/writes.css'
import { render } from "react-dom";


const heightOutPut = window.screen.height;






export default function TreeMenu() {
  const [writeopen, setwriteopen] = useState(false)
  const [boxes, setboxes] = useState([]);
  const [Box, setBox] = useState("INBOX");
  const [hide, setHide] = useState(false);
  const [openDilog, SetOpenDilog] = useState(false);
  const [emails, setEmails] = useState([]);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [isComposeList, setIsComposeList] = useState([]);
  const [editorHtml, setEditorHtml] = React.useState('');
  const [placeholder, setPlaceholder] = useState('Recipients');
  const [activeCC, setActiveCC] = useState(false);
  const [activeBCC, setActiveBCC] = useState(false);
  const [hideInput, sethideInput] = useState(false)
  const [themeColor, setThemeColor] = useState(true);
  const [activeBox, setActiveBox] = useState(null)

  // const handleThemeChange = () => {
  //   setThemeColor(!themeColor);
  // }

  const isItemActive = (itemValue) => {  // Or itemValue === 'someValue' if itemValue is a string

    // Compare with activeBox (also a boolean)
    return  (itemValue === activeBox);
  };

  const [containerHeight, setContainerHeight] = useState(window.innerHeight * 0.75);

  const handleResize = () => {
    // Update container height based on screen size
    setContainerHeight(window.innerHeight * 0.75);
  };

  useEffect(() => {
    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleTextClick = () => {
    setActiveBCC(true);
  };

  const handleTextClickCC = () => {
    setActiveCC(true);
  };

  const handleFocus = () => {
    setPlaceholder('To');
  };

  const handleBlur = () => {
    setPlaceholder('Recipients');
  };

  const handleFocusCcBcc = () => {
    sethideInput(true);
  }

  const handleBlurCcBcc = () => {

  }

  /// List of Icons for compose
  const listoBoxIcons = [<CreateIcon />, <StarBorderIcon />, <SendIcon />, <DraftsIcon />];
  //check if loggedin
  const token = localStorage.getItem("_mail_token");
  if (!token) {
    window.location.href = "/login";
  }

  const cancelWriteMesage = () => {
    setwriteopen(!writeopen)
  }

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const ShowHide = () => {
    setHide(!hide)
  }
  const onWriteCLick = (e) => {
    // e.preventDefault();
    setwriteopen(!writeopen)
  }



  const handleProcedureContentChange = (html) => {
    setEditorHtml(html);
  };

  const toCamelCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleMenuItemClick = (component) => {
    setBox(component)
    setActiveBox(component)
  };

  //To get menu list
  const MenuListData = async () => {
    const token = localStorage.getItem("_mail_token");
    const headers = {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json'
    };

    try {
      const url = process.env.REACT_APP_MAIL_API_URL + 'mail/boxes';
      const response = await axios.get(url,
        {
          headers: headers
        });
      if (response.status === 200) {
        const mailboxData = response.data;
        const customOrder = ["INBOX", "Sent", "Drafts", "Starred", "Junk"];

        const mailboxes = Object.keys(mailboxData).sort((a, b) => {
          const indexOfA = customOrder.indexOf(a);
          const indexOfB = customOrder.indexOf(b);

          if (indexOfA === -1 && indexOfB === -1) {
            return 0;
          } else if (indexOfA === -1) {
            return 1;
          } else if (indexOfB === -1) {
            return -1;
          } else {
            return indexOfA - indexOfB;
          }
        });
        const mailbox = mailboxes.map((mailbox) => {
          return {
            label: toCamelCase(mailbox),
            value: mailbox,
          };
        });
        setboxes(mailbox)
      } else {
        const error = {
          label: "Error Returning Data",
          value: "error",
        }
        setboxes(error)
      }
    } catch (error) {
      const error_text = {
        label: "Error Returning Data",
        value: error
      }
      setboxes(error)
    }
  };

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline",],
      [{ list: "ordered" },
      { list: "bullet" }
      ],

      ["link", "image"],
      [
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];


  const SendMail = async () => {
    const to = emails.join(",");
    const ccEmail = cc.join(",");
    const bccEmail = bcc.join(",");
    const subjectEmail = subject;
    const bodyEmail = editorHtml;
    const token = localStorage.getItem("_mail_token");
    const headers = {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json'
    };
    const data = {
      to: to,
      cc: ccEmail,
      bcc: bccEmail,
      subject: subjectEmail,
      html: bodyEmail,
      text: bodyEmail
    }

    const config = {
      url: process.env.REACT_APP_MAIL_API_URL + 'mail/sendmail',
      method: 'post',
      maxBodyLength: Infinity,
      headers: headers,
      data: data
    }
    try {
      const response = await axios(config);
      if (response.status === 200) {
        setwriteopen(!writeopen)
        setEmails([]);
      }
    }
    catch (error) {
      console.log("Error", error);
    }
  }

  useEffect(() => {
    MenuListData();
  }, []);

  return (
    <>
      <MenuDashboard themeColor={themeColor} />
      <div style={{
        height: `${heightOutPut}px`
      }} className="main-tree">
        {
          writeopen &&
          (
            // !isMinimized ? (
            <>
              <div className="main-write-container" style={{
                // height: `${containerHeight}px`
                width: "95%",
                height: "100%",
                position: "absolute"
              }}>
                <div style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end"
                }}>
                  <div className="submain-write-container">
                    <div className={`${themeColor ? "heading-write-container" : "heading-write-container-theme"}`} onClick={toggleMinimize}>
                      <p>New Mesage</p>
                      <div className="icons-write">
                        <p style={{ cursor: 'pointer' }}><MinimizeIcon onClick={toggleMinimize} />
                          <CloseIcon onClick={() => cancelWriteMesage()} />
                        </p>
                      </div>
                    </div>
                    {
                    !isMinimized ?(
                     <>
                      <div className="write-input1 TO-input">
                        <ReactMultiEmail
                          className="reactMultiEmail-emails"
                          placeholder={placeholder}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          emails={emails}
                          onChange={(_emails) => {
                            setEmails(_emails);
                          }}
                          validateEmail={(email) => {
                            return true;
                          }}
                          getLabel={(email, index, removeEmail) => {
                            return (
                              <div style={{
                                height: `${heightOutPut / 45}px}`
                              }} data-tag key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                </span>
                              </div>
                            );
                          }
                        }
                        />

                        {
                          (
                            <div className={`cc-bcc-container ${activeBCC || activeCC ? "hide-bcc-cc" : "hide"}`}>
                              <span className="CC-text" onClick={handleTextClickCC}>CC</span>
                              <span className="BCC-text" onClick={handleTextClick}>BCC</span>
                            </div>
                          )
                        }

                      </div>
                        


                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%"
                    }}>
                      {
                        activeCC &&
                        (<div className="write-input1 write-CC" >
                          <ReactMultiEmail
                            className="reactMultiEmail-emails"
                            placeholder="CC"
                            onFocus={handleBlurCcBcc}
                            onBlur={handleBlurCcBcc}
                            cc={cc}
                            onChange={(_emails) => {
                              setCc(_emails);
                            }}
                            validateEmail={(cc) => {
                              return true;
                            }}
                            getLabel={(cc, index, removeEmail) => {
                              return (
                                <div data-tag key={index}>
                                  {cc}
                                  <span data-tag-handle onClick={() => removeEmail(index)}>
                                    X
                                  </span>
                                </div>
                              );
                            }}
                          />
                          <div className={`cc-bcc-container ${activeBCC && activeCC ? "hide-bcc-cc" : "hide"}`}><span className="BCC-text" onClick={handleTextClick}>BCC</span></div>
                        </div>)
                      }



                      {/* <div className="CCBC-txt"><p>CC</p><p>BC</p></div> */}
                      <div>
                        {activeBCC &&
                          (<div className="write-input1 write-BCC" >
                            <ReactMultiEmail
                              className="reactMultiEmail-emails"
                              placeholder="BCC"
                              onFocus={handleFocusCcBcc}
                              onBlur={handleBlurCcBcc}
                              bcc={bcc}
                              onChange={(_emails) => {
                                setBcc(_emails);
                              }}
                              validateEmail={(bcc) => {
                                return true;
                              }}
                              getLabel={(bcc, index, removeEmail) => {
                                return (
                                  <div data-tag key={index}>
                                    {bcc}
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                      X
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <div className={`cc-bcc-container ${activeBCC && activeCC ? "hide-bcc-cc" : "hide"}`}><span className={`CC-text ${activeBCC || activeCC} ? "hide-bcc-cc" : "hide"`} onClick={handleTextClickCC}>CC</span></div>
                          </div>)
                        }
                        <div>
                          <input className="CCBC-hide write-input" type="email" placeholder="CC" />
                          <input className="CCBC-hide write-input" type="email" placeholder="BCC" />
                          <input className="write-input" style={{padding:"10px"}} type="text" name="subject" placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />
                        </div>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="write message here ...."
                          onChange={handleProcedureContentChange}
                          // style={{ height: "75vh", width: '100%' }
                          style={{ height: "378px", width: '100%' }
                          }
                        >
                        </ReactQuill>

                      </div>
                      <div className='reply-btns'><button className="write-btn-forward" onClick={() => SendMail([emails, cc, bcc, subject, editorHtml])}> Send</button></div>

                    </div>
                        
                     </>
                        ):
                        ( 
                          <div className="minimized-container">
                          <div className="heading-write-container1" onClick={toggleMinimize}>
                            {/* <p>New Message</p> */}
                            <div className="icons-write">
                              <p style={{ cursor: 'pointer' }}>
                                {/* <MinimizeIcon onClick={toggleMinimize} />
                                <CloseIcon onClick={() => cancelWriteMesage()} /> */}
                              </p>
                            </div>
                          </div>
                        </div>
                        )
                        }
                  </div>
                </div>
              </div>
            </>
            //  ):( 
            //   <>
            //   <div className="minimized-container" >
            //     <div className="heading-write-container1" onClick={toggleMinimize}>
            //       <p>New Mesage</p>
            //       <div className="icons-write">
            //         <p style={{ cursor: 'pointer' }}><MinimizeIcon onClick={toggleMinimize} /><CloseIcon onClick={() => cancelWriteMesage()} /></p>
            //       </div>
            //     </div>
            //   </div>
            //   </>
            // )
          )
        }

        {
          !hide && (
            <div className={`${themeColor ? "main-tree-container" : "main-tree-themecolor"}`}>
              <div className={`${themeColor ? "logo-img-container" : "themeDark"}`}><img src={Logo_removebg} className="logo-image" /></div>
              <div className='mid-sec-wrap'>
                <ul className={`${themeColor ? "white-menu-list li" : "white-menu-list-theme li"}`}>
                  <li className={`${!themeColor ? "compose-container" : "compose-container-theme"}`} onClick={(e) => onWriteCLick(e)}><CreateIcon /> Compose</li>
                  {Array.isArray(boxes) ? (
                    boxes.map((item, index) => (
                      <li className={`${isItemActive(item.value) ? "active-box-menu":"main-treemenu-menu"}`} key={index} onClick={() => handleMenuItemClick(item.value)}>
                        {item.label}
                      </li>
                    ))
                  ) : (
                    <p style={{ fontSize: "20px" }}>Loading...</p>
                  )}
                  {/* <li className="main-treemenu-menu" onClick={() => handleThemeChange()}><DarkModeIcon/> </li> */}
                  {/* <li className="main-treemenu-menu" ><DarkModeIcon/> </li> */}
                </ul>
              </div>
            </div>
            //  </div>
          )
        }
        {
          hide ? (
            <button className={`  ${!themeColor ? "lefticon-theme" : " lefticon righticon"}`} onClick={() => ShowHide(!setHide)}>< KeyboardDoubleArrowRightIcon /></button>
          ) : <button className={`${themeColor ? "lefticon" : "lefticon-theme"}`} onClick={() => ShowHide(!setHide)}><KeyboardDoubleArrowLeftIcon /></button>
        }
        {Box && (
          <MainDashboard Box={Box} hide={hide} themeColor={themeColor} />
        )}
      </div >
    </>
  );
}

{/* <button className=" lefticon " onClick={() => ShowHide(!setHide)}><KeyboardDoubleArrowLeftIcon /></button> */ }