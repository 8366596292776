import "../../css/MainDashboard.css";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Ellipse10 from "../../img/Ellipse10.jpg";
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import Ellipse11 from "../../img/Ellipse11.jpg";
import Ellipse12 from "../../img/Ellipse12.jpg";
import Ellipse13 from "../../img/Ellipse13.jpg";
import Ellipse14 from "../../img/Ellipse14.jpg";
import { Checkbox } from "@mui/material";
import Footer from "../footer.js/Footer";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Unread from '../Unread/Unread';
import '../Loader/Loader.css';

export default function MailBox({ List, Box, pageSplit, themeColor }) {
    const [OpenedMail, setOpenedMail] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [SeenFlag, setSeenFlag] = useState(false);
    const [Mails, setMails] = useState(List);
    const [CountMails, setCountMails] = useState(0);
    const [changepassword, setchangepassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [Uid, setUid] = useState(0);
    const [splitMail, setSplitMail] = useState(false);
    const [apiresponse, setApiresponse] = useState([]);
    const [is_starred, setIs_starred] = useState(false);
    const [is_unread, setIs_unread] = useState(false);
    // const[hideMail,setHideMail]=useState(false);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // Simulate an async operation (e.g., fetching data from an API)
        setTimeout(() => {
            <p>No Mails</p>
            setIsLoading(false); // Data loading is complete
        }, 2000);
        // Simulated 2-second delay
    }, [apiresponse]);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const OpenMailhandler = (uid) => {
        console.log("openedmaileeeee "+!OpenedMail);
        setOpenedMail(!OpenedMail);
        setSplitMail(!splitMail);
        setOpenedMail(true);
        setUid(uid);
    }
    const getMonthShortName = (monthNo) => {
        const date = new Date();
        date.setMonth(monthNo - 1);
        return date.toLocaleString('en-US', { month: 'short' });
    }

    // const HandleFlagitem = (flag, uid) => {
    //     const token = localStorage.getItem("_mail_token");
    //     const headers = {
    //         "Authorization": "Bearer " + token,
    //         "Content-Type": "application/json",
    //         "mode": "no-cors",
    //     };
    //     if (flag === 'seen') {
    //         const url = process.env.REACT_APP_MAIL_API_URL + "mail/" + Box + "/flag/" + uid;

    //         fetch(url, {
    //             method: "POST",
    //             headers: headers,
    //             body: JSON.stringify({ flag }),
    //         })

    //             .then((res) => res.json())
    //             .then((res) => {
    //                 console.log(res);
    //                 setApiresponse(res);
    //             });
    //     }
    //     else if (flag === 'unseen') {
    //         const url = process.env.REACT_APP_MAIL_API_URL + "mail/" + Box + "/flag/" + uid + "?flag=" + flag;
    //         fetch(url, {
    //             method: 'POST',
    //             headers: headers,
    //             redirect: 'follow'
    //         })
    //             .then((res) => res.json())
    //             .then((res) => {
    //                 console.log(res);
    //                 setApiresponse(res);
    //             })
    //     }
    //     else if (flag === 'archive') {
    //     }
    //     else if (flag === 'delete') {
    //         const url = process.env.REACT_APP_MAIL_API_URL + "mail/" + Box + "?uids" + uid;
    //         fetch(url, {
    //             method: 'POST',
    //             headers: headers,
    //             redirect: 'follow'
    //         })
    //             .then((res) => res.json())
    //             .then((res) => {
    //                 console.log(res);
    //                 setApiresponse(res);
    //             })
    //     }
    // }

    const HandleFlagitem = (flag, uid) => {
        const [loading, setLoading] = useState(true);
        const [errorMessage, setErrorMessage] = useState(null);

        const token = localStorage.getItem("_mail_token");
        const headers = {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "mode": "no-cors",
        };

        useEffect(() => {
            let url = '';
            if (flag === 'seen') {
                url = `${process.env.REACT_APP_MAIL_API_URL}mail/${Box}/flag/${uid}`;
            } else if (flag === 'unseen') {
                url = `${process.env.REACT_APP_MAIL_API_URL}mail/${Box}/flag/${uid}?flag=${flag}`;
            } else if (flag === 'delete') {
                url = `${process.env.REACT_APP_MAIL_API_URL}mail/${Box}?uids=${uid}`;
            }

            fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ flag }),
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return res.json();
                })
                .then((res) => {
                    console.log(res);
                    setApiresponse(res);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setErrorMessage('Failed to fetch data.');
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [uid, flag]);  // Include uid and flag in the dependency array if they're used inside useEffect
    }

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const now = new Date();
        const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
        if (diffInDays === 0) {
            const h = date.getHours();
            const m = date.getMinutes();
            const ampm = h >= 12 ? 'pm' : 'am';
            const hours = h % 12;
            const minutes = m < 10 ? '0' + m : m;
            return `${hours}:${minutes} ${ampm}`;
        }
        if (diffInDays === 1) return 'Yesterday';
        if (diffInDays < 7) return `${diffInDays} days ago`;
        const d = date.getDate();
        const m = getMonthShortName(date.getMonth());
        const y = date.getFullYear() === now.getFullYear() ? '' : ` ${date.getFullYear()}`;
        return `${d} ${m}${y}`;
    };


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const toCamelCase = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (

        <div className="msg-content-split">
            {
                !OpenedMail &&
                <div className={` ${(!pageSplit) ? "message-content-active" : "Message-content"}`}>
                    {/* <div className={}>
            </div> */}
                    {isLoading ? (
                        <div className="loader-container">
                            <div className="border-line-loader"></div>
                        </div>
                        // <div className="la-square-loader la-dark la-3x">
                        //     <div></div>
                        // </div>
                    ) :
                        (Array.isArray(List) ?
                            (List?.map((item, index) => {
                                console.log("MailBox component rendering");

                                return (
                                    <>
                                        {
                                            (// !OpenedMail &&
                                            // OpenMailData?.attributes.uid, OpenMailData?.attributes?.flags.includes("\\Seen") ? "unread" : "read")
                                                (<div className={`msg-box ${OpenedMail ? "active-msg-box read message-box-container" : "  active-message-box-nosplit unread"} 
                                                ${item.attributes && item.attributes.flags.includes('\\Seen') || OpenedMail? ' message-box-container read' : 'message-box-container-unseen unread'} 
                                                ${pageSplit ? "message-content-split" : "message-content-nosplit"}`} key={index} onClick={() => OpenMailhandler(item.attributes && item.attributes.uid)}>
                                                    <div className="msg-check-mox">
                                                        <label className={`custom-checkbox ${isChecked ? 'checked' : ''}`}> 
                                                            {/* <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={toggleCheckbox}
                                                /> */}
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        {/*  <StarBorderIcon className="msg-box-icon" /> */}
                                                    </div>

                                                    <p className="common-font msg-user-name ">
                                                        {item.headers.from.value[0].name
                                                            ? item.headers.from.value[0].name
                                                            : item.headers.from.value[0].address}
                                                    </p>
                                                    <p className="msg-subject">
                                                        {item.headers.subject && item.headers.subject.replace(/<[^>]+>/g, "").slice(0, 50)} - <span className="message">
                                                            {item.text && item.text.replace(/<[^>]+>/g, "").slice(0, 100)}
                                                        </span>
                                                    </p>
                                                    {/* {item.attachments.length > 0 && (
                                                        <AttachEmailOutlinedIcon className={"msg-box-icon fit-icons attachment-icon"} />
                                                    )} */}
                                                    {/* <div className="right-msg-cont"> */}

                                                    {/* {item.attributes.flags.length === 0 && (
                                                            <MarkEmailReadIcon className=" hovericons fit-icons" onClick={() => HandleFlagitem('seen', item.attributes.uid)} />
                                                        )}
                                                        {item.attributes.flags.length > 0 && (
                                                            <MarkAsUnreadIcon className=" hovericons fit-icons" onClick={() => HandleFlagitem('unseen', item.attributes.uid)} />
                                                        )}
                                                        {<ArchiveIcon className=" hovericons fit-icons" onClick={() => HandleFlagitem('archive', item.attributes.uid)} />}
                                                        {<DeleteIcon className=" hovericons fit-icons" onClick={() => HandleFlagitem('delete', item.attributes.uid)} />} */}
                                                    {/* </div> */}
                                                    <p className="common-font msg-date">{formatDate(item.attributes.date)}</p>

                                                </div>)
                                            )
                                        }
                                    </>
                                );
                            })) : (
                                <div className="no-mails">
                                    <p className="common-font">No mails in your {toCamelCase(Box)}</p>
                                </div>
                            )
                        )
                    }
                </div >


            }

            {
                !OpenedMail ?
                    (!pageSplit &&
                        (<div className="storage-container">
                            <div>
                                <p>No Conversation selected</p>
                            </div>
                        </div>)

                    )
                    :
                    (
                        <Unread Box={Box} uid={Uid} splitMail={splitMail} OpenedMail={OpenedMail} pageSplit={pageSplit} themeColor={themeColor} />

                    )
            }

            {
                OpenedMail === true && (
                    !pageSplit &&
                    <Unread Box={Box} uid={Uid} splitMail={splitMail} OpenedMail={OpenedMail} pageSplit={pageSplit} themeColor={themeColor} />
                )
            }

        </div>
    );
}