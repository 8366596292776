// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./wezbo_doddle.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.loader-container {
    display: flex;
    margin: auto;
    margin-top: 20%;
    height: 15vh;
    width: 15vh;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    animation: spin 1s linear infinite;
    /* border: 1px solid rgb(235, 210, 210); */
    /* box-shadow: 2px 2px 3px 4px rgba(241, 238, 238, 0.2); */
    background-size: 100%;
    /* border-radius: 25px; */
    /* opacity: .8; */
    /* transition: all .5s ease-in-out; */
}

.border-line-loader {
    width: 20px;
    height: 20px;
    /* border: 4px solid transparent; */
    margin: auto;
    /* border-radius: 50%; */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
} 


/*  */`, "",{"version":3,"sources":["webpack://./src/Component/Loader/Loader.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,WAAW;IACX,yDAA2C;IAC3C,4BAA4B;IAC5B,kCAAkC;IAClC,0CAA0C;IAC1C,0DAA0D;IAC1D,qBAAqB;IACrB,yBAAyB;IACzB,iBAAiB;IACjB,qCAAqC;AACzC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mCAAmC;IACnC,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC;;;AAGA,KAAK","sourcesContent":["\r\n.loader-container {\r\n    display: flex;\r\n    margin: auto;\r\n    margin-top: 20%;\r\n    height: 15vh;\r\n    width: 15vh;\r\n    background-image: url(\"./wezbo_doddle.png\");\r\n    background-repeat: no-repeat;\r\n    animation: spin 1s linear infinite;\r\n    /* border: 1px solid rgb(235, 210, 210); */\r\n    /* box-shadow: 2px 2px 3px 4px rgba(241, 238, 238, 0.2); */\r\n    background-size: 100%;\r\n    /* border-radius: 25px; */\r\n    /* opacity: .8; */\r\n    /* transition: all .5s ease-in-out; */\r\n}\r\n\r\n.border-line-loader {\r\n    width: 20px;\r\n    height: 20px;\r\n    /* border: 4px solid transparent; */\r\n    margin: auto;\r\n    /* border-radius: 50%; */\r\n}\r\n\r\n@keyframes spin {\r\n    0% { transform: rotate(0deg); }\r\n    100% { transform: rotate(360deg); }\r\n} \r\n\r\n\r\n/*  */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
