import '../../css/Footer.css';

const Footer = ()=>{
    return(
        <footer className='footer-container'>
                    <p className='footer-line'>Made by Wezbo</p>
        
        </footer>
    )
}
export default Footer;