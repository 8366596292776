

import React, { useState, useEffect } from 'react'
import './Login.css';
import Logo_removebg from './LoginImg/Logo_removebg.png';
import emailicon from './icon/emailicon.svg';
import LockIcon from './icon/LockIcon.svg';
import hide2 from './icon/hide2.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { type } from '@testing-library/user-event/dist/type';

export default function Login() {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const togglePasswordVisibility = (e) => {
        setShowPassword(!showPassword);
        // e.preventDefault();
    };

    // const handleKeyPress = (event) => {
    //     if (event.key === 'Enter') {
    //         console.log('enter press here! ')
    //     }
    // }

    const handleEmail = (e) => {
        setEmail(e.target.value);
        e.preventDefault();
    };

    const toCamelCase = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("https://ap-south.mail.api.wezbogroup.com/user/signin", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();
            if (data.user.success == true) {
                localStorage.setItem("_mail_token", data.token);
                localStorage.setItem("_mail_user", email);
                window.location.href = "/";
            }
            else{
                toast.error(data.message);
            }
        } catch (error) {
                toast.error('Failed to fetch data from the server.');
          }
    };

    const token = localStorage.getItem("_mail_token");
    if (token) {
        window.location.href = "/";
    }

    return (
        <div className="main-container">
            <div> <img src={Logo_removebg} className="email-logo" /></div>
            <div className='sub-container'>
            <ToastContainer />
                <div className="login-main-container1">
                    <form onSubmit={handleLogin}>
                        <div className="head-container">
                            <h3 className='login-text'>Log In</h3>
                        </div>

                        <div className="inputs-container">
                            <label className='login-label'>Email</label>
                            <img src={emailicon} className="email-icon" />
                            <input type="email" name="email" placeholder="Email" required className="login-input" value={email} onChange={handleEmail} />
                        </div>

                        <div className="inputs-container">
                            <label className='login-label'>Password</label>
                            <img src={LockIcon} className="email-icon" />

                            <input type={showPassword ? 'text' : 'password'} value={password} name="password" placeholder="Password" required className='login-input'
                                onChange={(e) => setPassword(e.target.value)} />

                            <p onClick={ togglePasswordVisibility} className='hide-btn'
                            >{showPassword ? <VisibilityIcon /> : < VisibilityOffIcon />}
                            </p>
                        </div>

                        <div className="btn-container">
                            <input className="Login-btn" type="submit" value="Log In"
                            // onChange={(e) => setPassword(e.target.value)}
                            // onKeyPress={handleKeyPress}
                            />
                        </div>
                        {error && <div className="error-message">{error}</div>}
                    </form>
                </div>
            </div>
        </div>
    )
}
