


import React from 'react'
import "../../css/MainDashboard.css";
import '../../css/rightOfInbox.css'
import "../../css/global.css";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MainDashboard from '../../Component/MainDashboard/MainDashboard';
import TreeMenu from '../../Component/TreeMenu/TreeMenu';
import MenuDashboard from '../../Component/MenuDashboard/MenuDashboard';
import { useEffect } from 'react';
import { useState } from 'react';
// import SideMenuTreeMenu from '../../Component/LeftComponent/SideMenuTreeMenu';


export default function RightOfInbox() {
  //send api call with token
  const token = localStorage.getItem("_mail_token");
  const [List, setList] = useState([]);
  const [Box, setBox] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const headers = {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json'
      };

      try {
        const response = await fetch('https://ap-south.mail.api.wezbogroup.com/mail/INBOX/all', {
          method: 'GET',
          headers: headers,
        });
        if (response.status === 200) {
          const data = await response.json();
          setList(data.emails);
          setBox(data.boxName);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);


  return (
    <div className='main-container-social '>
      <TreeMenu />
      <div className='mail-dash-wrapper'>
        <div><MainDashboard List={List} Box={Box} /></div>
        <div className='footerheader-container'>
        </div>
      </div>
    </div>
  )
}
